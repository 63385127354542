@import 'app/shared/theme/layout';

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: 1;
}
